<template>
  <svg
    id="freepik_stories-operating-system"
    class="animated"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
  >
    <g
      id="freepik--background-complete--inject-2"
      class="animable"
      style="transform-origin: 250px 228.23px;"
    >
      <rect
        id="elp6kv37bj4hg"
        y="382.4"
        width="500"
        height="0.25"
        style="fill: rgb(235, 235, 235); transform-origin: 250px 382.525px;"
        class="animable"
      ></rect>
      <rect
        id="elhkjpcwmbrk6"
        x="416.78"
        y="398.49"
        width="33.12"
        height="0.25"
        style="fill: rgb(235, 235, 235); transform-origin: 433.34px 398.615px;"
        class="animable"
      ></rect>
      <rect
        id="elebvoeqboj7"
        x="322.53"
        y="401.21"
        width="8.69"
        height="0.25"
        style="fill: rgb(235, 235, 235); transform-origin: 326.875px 401.335px;"
        class="animable"
      ></rect>
      <rect
        id="elqz8lm8nzy3j"
        x="396.59"
        y="389.21"
        width="19.19"
        height="0.25"
        style="fill: rgb(235, 235, 235); transform-origin: 406.185px 389.335px;"
        class="animable"
      ></rect>
      <rect
        id="elsk9tgvmh8pi"
        x="52.46"
        y="390.89"
        width="43.19"
        height="0.25"
        style="fill: rgb(235, 235, 235); transform-origin: 74.055px 391.015px;"
        class="animable"
      ></rect>
      <rect
        id="ely18wvuadxf"
        x="104.56"
        y="390.89"
        width="6.33"
        height="0.25"
        style="fill: rgb(235, 235, 235); transform-origin: 107.725px 391.015px;"
        class="animable"
      ></rect>
      <rect
        id="eljbt2f3efghc"
        x="131.47"
        y="395.11"
        width="93.68"
        height="0.25"
        style="fill: rgb(235, 235, 235); transform-origin: 178.31px 395.235px;"
        class="animable"
      ></rect>
      <path
        id="el5nup1ippxzm"
        d="M237,337.8H43.91a5.71,5.71,0,0,1-5.7-5.71V60.66A5.71,5.71,0,0,1,43.91,55H237a5.71,5.71,0,0,1,5.71,5.71V332.09A5.71,5.71,0,0,1,237,337.8ZM43.91,55.2a5.46,5.46,0,0,0-5.45,5.46V332.09a5.46,5.46,0,0,0,5.45,5.46H237a5.47,5.47,0,0,0,5.46-5.46V60.66A5.47,5.47,0,0,0,237,55.2Z"
        style="fill: rgb(235, 235, 235); transform-origin: 140.46px 196.4px;"
        class="animable"
      ></path>
      <path
        id="elh32znir7f"
        d="M453.31,337.8H260.21a5.72,5.72,0,0,1-5.71-5.71V60.66A5.72,5.72,0,0,1,260.21,55h193.1A5.71,5.71,0,0,1,459,60.66V332.09A5.71,5.71,0,0,1,453.31,337.8ZM260.21,55.2a5.47,5.47,0,0,0-5.46,5.46V332.09a5.47,5.47,0,0,0,5.46,5.46h193.1a5.47,5.47,0,0,0,5.46-5.46V60.66a5.47,5.47,0,0,0-5.46-5.46Z"
        style="fill: rgb(235, 235, 235); transform-origin: 356.75px 196.4px;"
        class="animable"
      ></path>
      <path
        id="elqibmjohv58"
        d="M443.18,78.37V74.08L441,73.87a11.53,11.53,0,0,0-1.65-4l1.42-1.71-3-3L436,66.57a11.53,11.53,0,0,0-4-1.65l-.2-2.21h-4.3l-.21,2.21a11.48,11.48,0,0,0-4,1.65l-1.72-1.42-3,3L420,69.9a11.34,11.34,0,0,0-1.65,4l-2.21.21v4.29l2.21.21a11.34,11.34,0,0,0,1.65,4l-1.42,1.71,3,3,1.72-1.42a11.48,11.48,0,0,0,4,1.65l.21,2.21h4.3l.2-2.21a11.53,11.53,0,0,0,4-1.65l1.72,1.42,3-3-1.42-1.71a11.53,11.53,0,0,0,1.65-4ZM429.67,82a5.82,5.82,0,1,1,5.81-5.82A5.82,5.82,0,0,1,429.67,82Z"
        style="fill: rgb(224, 224, 224); transform-origin: 429.66px 76.235px;"
        class="animable"
      ></path>
      <path
        id="el815mnyoue9j"
        d="M460.59,90.49V87.13L458.85,87a9,9,0,0,0-1.29-3.1l1.12-1.35-2.38-2.38L455,81.25a8.93,8.93,0,0,0-3.11-1.3l-.16-1.73h-3.36L448.15,80a9,9,0,0,0-3.11,1.3l-1.34-1.12-2.38,2.38,1.11,1.35a9.14,9.14,0,0,0-1.29,3.1l-1.73.17v3.36l1.73.17a9,9,0,0,0,1.29,3.1l-1.11,1.35,2.38,2.38L445,96.37a9,9,0,0,0,3.11,1.3l.17,1.73h3.36l.16-1.73a8.93,8.93,0,0,0,3.11-1.3l1.35,1.12,2.38-2.38-1.12-1.35a9,9,0,0,0,1.29-3.1ZM450,93.37a4.56,4.56,0,1,1,4.55-4.56A4.56,4.56,0,0,1,450,93.37Z"
        style="fill: rgb(224, 224, 224); transform-origin: 450px 88.81px;"
        class="animable"
      ></path>
      <path
        id="elc3umgkuwwyb"
        d="M443.18,100.05V97.22l-1.45-.14a7.46,7.46,0,0,0-1.09-2.61l.94-1.13-2-2-1.13.94a7.65,7.65,0,0,0-2.61-1.09l-.14-1.45h-2.82l-.14,1.45a7.65,7.65,0,0,0-2.61,1.09L429,91.34l-2,2,.93,1.13a7.63,7.63,0,0,0-1.08,2.61l-1.46.14v2.83l1.46.13a7.63,7.63,0,0,0,1.08,2.61l-.93,1.13,2,2,1.13-.93a7.44,7.44,0,0,0,2.61,1.08l.14,1.46h2.82l.14-1.46a7.44,7.44,0,0,0,2.61-1.08l1.13.93,2-2-.94-1.13a7.46,7.46,0,0,0,1.09-2.61Zm-8.89,2.41a3.83,3.83,0,1,1,3.82-3.83A3.83,3.83,0,0,1,434.29,102.46Z"
        style="fill: rgb(224, 224, 224); transform-origin: 434.285px 98.635px;"
        class="animable"
      ></path>
      <polygon
        id="elch9uyk2nrcl"
        points="50.47 381.47 72.81 232.98 72.81 381.47 78.81 381.47 78.81 232.05 101.19 381.47 107.61 381.47 79.61 194.44 78.81 194.56 78.81 188.47 72.81 188.47 72.81 194.53 71.99 194.44 43.92 381.47 50.47 381.47"
        style="fill: rgb(224, 224, 224); transform-origin: 75.765px 284.97px;"
        class="animable"
      ></polygon>
      <polygon
        id="elyyqu4wiixuf"
        points="106.56 215.18 44.74 215.18 49.75 163.53 101.56 163.53 106.56 215.18"
        style="fill: rgb(235, 235, 235); transform-origin: 75.65px 189.355px;"
        class="animable"
      ></polygon>
      <text
        transform="translate(64.11 78.4)"
        style="font-size:12px;fill:#e0e0e0;font-family:Bebas"
      >
        <tspan style="letter-spacing:0.025553385416666668em">1</tspan>
        <tspan x="4.4" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="10.81" y="0" style="letter-spacing:0.017740885416666668em">
          11
        </tspan>
        <tspan x="19.41" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="23.81" y="0" style="letter-spacing:0.023478190104166668em">
          00
        </tspan>
        <tspan x="36.8" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="43.21" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="47.61" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="54.01" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="58.41" y="0" style="letter-spacing:0.016357421875em">0</tspan>
        <tspan x="64.82" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="69.22" y="0" style="letter-spacing:0.0234375em">00</tspan>
        <tspan x="82.21" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="88.62" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="93.01" y="0" style="letter-spacing:0.016357421875em">0</tspan>
        <tspan x="99.42" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="103.82" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="110.23" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="114.63" y="0" style="letter-spacing:0.016357421875em">
          0
        </tspan>
        <tspan x="121.04" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="125.43" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="131.84" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="136.24" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="142.65" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="147.04" y="0" style="letter-spacing:0.016357421875em">
          0
        </tspan>
        <tspan x="153.45" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="157.85" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="164.26" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="168.66" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="175.07" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="179.46" y="0" style="letter-spacing:0.0234375em">
          000000
        </tspan>
        <tspan x="218.43" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="224.84" y="0" style="letter-spacing:0.017740885416666668em">
          11111
        </tspan>
        <tspan x="246.36" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="250.75" y="0" style="letter-spacing:0.023478190104166668em">
          0
        </tspan>
        <tspan x="257.25" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="263.66" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="268.05" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="274.46" y="0" style="letter-spacing:0.017740885416666668em">
          111
        </tspan>
        <tspan x="287.37" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="291.77" y="0" style="letter-spacing:0.016357421875em">
          0
        </tspan>
        <tspan x="298.18" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="302.58" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="308.99" y="0">1</tspan>
      </text>
      <text
        transform="translate(142.56 319.29)"
        style="font-size:12px;fill:#e0e0e0;font-family:Bebas"
      >
        <tspan style="letter-spacing:0.025553385416666668em">1</tspan>
        <tspan x="4.4" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="10.81" y="0" style="letter-spacing:0.017740885416666668em">
          11
        </tspan>
        <tspan x="19.41" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="23.81" y="0" style="letter-spacing:0.023478190104166668em">
          00
        </tspan>
        <tspan x="36.8" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="43.21" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="47.61" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="54.01" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="58.41" y="0" style="letter-spacing:0.016357421875em">0</tspan>
        <tspan x="64.82" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="69.22" y="0" style="letter-spacing:0.0234375em">00</tspan>
        <tspan x="82.21" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="88.62" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="93.01" y="0" style="letter-spacing:0.016357421875em">0</tspan>
        <tspan x="99.42" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="103.82" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="110.23" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="114.63" y="0" style="letter-spacing:0.016357421875em">
          0
        </tspan>
        <tspan x="121.04" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="125.43" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="131.84" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="136.24" y="0" style="letter-spacing:0.0234375em">00</tspan>
        <tspan x="149.23" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="155.64" y="0" style="letter-spacing:0.017740885416666668em">
          1111
        </tspan>
        <tspan x="172.85" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="177.25" y="0" style="letter-spacing:0.016357421875em">
          0
        </tspan>
        <tspan x="183.66" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="188.05" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="194.46" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="198.86" y="0" style="letter-spacing:0.016357421875em">
          0
        </tspan>
        <tspan x="205.27" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="209.67" y="0" style="letter-spacing:0.0234375em">000</tspan>
        <tspan x="229.15" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="235.56" y="0" style="letter-spacing:0.017740885416666668em">
          11
        </tspan>
        <tspan x="244.17" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="248.56" y="0" style="letter-spacing:0.016357421875em">
          0
        </tspan>
        <tspan x="254.97" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="259.37" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="265.78" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="270.18" y="0" style="letter-spacing:0.0234375em">0000</tspan>
        <tspan x="296.15" y="0">0</tspan>
      </text>
      <text
        transform="translate(85.45 115.43)"
        style="font-size:12px;fill:#e0e0e0;font-family:Bebas"
      >
        <tspan style="letter-spacing:0.016316731770833332em">0</tspan>
        <tspan x="6.41" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="10.81" y="0" style="letter-spacing:0.016357421875em">0</tspan>
        <tspan x="17.22" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="21.61" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="28.02" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="32.42" y="0" style="letter-spacing:0.0234375em">00</tspan>
        <tspan x="45.41" y="0" style="letter-spacing:0.016357421875em">0</tspan>
        <tspan x="51.82" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="56.21" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="62.62" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="67.02" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="73.43" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="77.83" y="0" style="letter-spacing:0.016357421875em">0</tspan>
        <tspan x="84.24" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="88.63" y="0" style="letter-spacing:0.0234375em">000000</tspan>
        <tspan x="127.6" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="134.01" y="0" style="letter-spacing:0.017740885416666668em">
          11111
        </tspan>
        <tspan x="155.53" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="159.92" y="0" style="letter-spacing:0.0234375em">0</tspan>
        <tspan x="166.42" y="0" style="letter-spacing:0.016357421875em">
          0
        </tspan>
        <tspan x="172.83" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="177.22" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="183.63" y="0" style="letter-spacing:0.017740885416666668em">
          111
        </tspan>
        <tspan x="196.54" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="200.94" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="207.35" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="211.75" y="0" style="letter-spacing:0.016357421875em">
          0
        </tspan>
        <tspan x="218.16" y="0">1</tspan>
      </text>
      <text
        transform="translate(163.24 361.49)"
        style="font-size:12px;fill:#e0e0e0;font-family:Bebas"
      >
        <tspan style="letter-spacing:0.016316731770833332em">0</tspan>
        <tspan x="6.41" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="10.81" y="0" style="letter-spacing:0.016357421875em">0</tspan>
        <tspan x="17.22" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="21.61" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="28.02" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="32.42" y="0" style="letter-spacing:0.0234375em">00</tspan>
        <tspan x="45.41" y="0" style="letter-spacing:0.016357421875em">0</tspan>
        <tspan x="51.82" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="56.21" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="62.62" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="67.02" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="73.43" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="77.83" y="0" style="letter-spacing:0.016357421875em">0</tspan>
        <tspan x="84.24" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="88.63" y="0" style="letter-spacing:0.0234375em">000000</tspan>
        <tspan x="127.6" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="134.01" y="0" style="letter-spacing:0.017740885416666668em">
          11111
        </tspan>
        <tspan x="155.53" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="159.92" y="0" style="letter-spacing:0.0234375em">0</tspan>
        <tspan x="166.42" y="0" style="letter-spacing:0.016357421875em">
          0
        </tspan>
        <tspan x="172.83" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="177.22" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="183.63" y="0" style="letter-spacing:0.017740885416666668em">
          111
        </tspan>
        <tspan x="196.54" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="200.94" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="207.35" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="211.75" y="0" style="letter-spacing:0.016357421875em">
          0
        </tspan>
        <tspan x="218.16" y="0">1</tspan>
      </text>
      <text
        transform="translate(227.31 339.16)"
        style="font-size:12px;fill:#e0e0e0;font-family:Bebas"
      >
        <tspan style="letter-spacing:0.016316731770833332em">0</tspan>
        <tspan x="6.41" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="10.81" y="0" style="letter-spacing:0.016357421875em">0</tspan>
        <tspan x="17.22" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="21.61" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="28.02" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="32.42" y="0" style="letter-spacing:0.0234375em">00</tspan>
        <tspan x="45.41" y="0" style="letter-spacing:0.016357421875em">0</tspan>
        <tspan x="51.82" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="56.21" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="62.62" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="67.02" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="73.43" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="77.83" y="0" style="letter-spacing:0.016357421875em">0</tspan>
        <tspan x="84.24" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="88.63" y="0" style="letter-spacing:0.0234375em">000000</tspan>
        <tspan x="127.6" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="134.01" y="0" style="letter-spacing:0.017740885416666668em">
          11111
        </tspan>
        <tspan x="155.53" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="159.92" y="0" style="letter-spacing:0.0234375em">0</tspan>
        <tspan x="166.42" y="0" style="letter-spacing:0.016357421875em">
          0
        </tspan>
        <tspan x="172.83" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="177.22" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="183.63" y="0" style="letter-spacing:0.017740885416666668em">
          111
        </tspan>
        <tspan x="196.54" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="200.94" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="207.35" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="211.75" y="0" style="letter-spacing:0.016357421875em">
          0
        </tspan>
        <tspan x="218.16" y="0">1</tspan>
      </text>
      <text
        transform="translate(85.45 134)"
        style="font-size:12px;fill:#e0e0e0;font-family:Bebas"
      >
        <tspan style="letter-spacing:0.016316731770833332em">0</tspan>
        <tspan x="6.41" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="10.81" y="0" style="letter-spacing:0.016357421875em">0</tspan>
        <tspan x="17.22" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="21.61" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="28.02" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="32.42" y="0" style="letter-spacing:0.0234375em">00</tspan>
        <tspan x="45.41" y="0" style="letter-spacing:0.016357421875em">0</tspan>
        <tspan x="51.82" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="56.21" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="62.62" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="67.02" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="73.43" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="77.83" y="0" style="letter-spacing:0.016357421875em">0</tspan>
        <tspan x="84.24" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="88.63" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="95.04" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="99.44" y="0" style="letter-spacing:0.016357421875em">0</tspan>
        <tspan x="105.85" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="110.25" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="116.65" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="121.05" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="127.46" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="131.86" y="0" style="letter-spacing:0.016357421875em">
          0
        </tspan>
        <tspan x="138.27" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="142.66" y="0" style="letter-spacing:0.0234375em">
          000000
        </tspan>
        <tspan x="181.63" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="188.04" y="0" style="letter-spacing:0.017740885416666668em">
          11111
        </tspan>
        <tspan x="209.56" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="213.95" y="0" style="letter-spacing:0.0234375em">0</tspan>
        <tspan x="220.45" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="226.86" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="231.25" y="0" style="letter-spacing:0.016357421875em">
          0
        </tspan>
        <tspan x="237.66" y="0" style="letter-spacing:0.017740885416666668em">
          111
        </tspan>
        <tspan x="250.58" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="254.97" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="261.38" y="0" style="letter-spacing:0.025553385416666668em">
          1
        </tspan>
        <tspan x="265.78" y="0" style="letter-spacing:0.016316731770833332em">
          0
        </tspan>
        <tspan x="272.19" y="0">1</tspan>
      </text>
    </g>
    <g
      id="freepik--Shadow--inject-2"
      class="animable"
      style="transform-origin: 250px 416.24px;"
    >
      <ellipse
        id="freepik--path--inject-2"
        cx="250"
        cy="416.24"
        rx="193.89"
        ry="11.32"
        style="fill: rgb(245, 245, 245); transform-origin: 250px 416.24px;"
        class="animable"
      ></ellipse>
    </g>
    <g
      id="freepik--Window--inject-2"
      class="animable"
      style="transform-origin: 290.498px 193.5px;"
    >
      <path
        id="els3iugc1hby"
        d="M444,92.61V293.86a9.36,9.36,0,0,1-9,9.14H145.39c-4.81,0-8.39-4.32-8.39-9.14V92.61A8.36,8.36,0,0,1,145.39,84H435A8.91,8.91,0,0,1,444,92.61Z"
        style="fill: rgb(255, 64, 64); transform-origin: 290.498px 193.5px;"
        class="animable"
      ></path>
      <g id="elfdi1bytq5rl">
        <path
          d="M444,117.69V293.86a9.36,9.36,0,0,1-9,9.14H145.39c-4.81,0-8.39-4.32-8.39-9.14V225.29c25.48-49,86.22-56.94,157.33-28.29C395,237.56,437.93,182.51,444,117.69Z"
          style="fill: rgb(255, 255, 255); opacity: 0.1; isolation: isolate; transform-origin: 290.5px 210.345px;"
          class="animable"
        ></path>
      </g>
    </g>
    <g
      id="freepik--Bar--inject-2"
      class="animable"
      style="transform-origin: 291.46px 275px;"
    >
      <g id="el1iyd956rbw">
        <path
          d="M364.92,280H218a5,5,0,0,1-5-5h0a5,5,0,0,1,5-5H364.92a5,5,0,0,1,5,5h0A5,5,0,0,1,364.92,280Z"
          style="opacity: 0.2; isolation: isolate; transform-origin: 291.46px 275px;"
          class="animable"
        ></path>
      </g>
      <path
        id="elfo2i4fxtig4"
        d="M218,279a4,4,0,1,1,0-8h86.84a4,4,0,1,1,0,8Z"
        style="fill: rgb(255, 64, 64); transform-origin: 261.42px 275px;"
        class="animable"
      ></path>
      <g id="els62itwxt4ah">
        <path
          d="M218,279a4,4,0,1,1,0-8h86.84a4,4,0,1,1,0,8Z"
          style="fill: rgb(255, 255, 255); opacity: 0.7; isolation: isolate; transform-origin: 261.42px 275px;"
          class="animable"
        ></path>
      </g>
    </g>
    <g
      id="freepik--Character--inject-2"
      class="animable"
      style="transform-origin: 160.74px 336.965px;"
    >
      <g
        id="freepik--group--inject-2"
        class="animable"
        style="transform-origin: 160.74px 336.965px;"
      >
        <path
          id="elp1385zcdko"
          d="M159.93,287.26c9.32,7.49,6.07,43.24,7.82,48.74S177,346,177,346l-10.46,12.25s-8.75-1.52-15.4-15.77S159.93,287.26,159.93,287.26Z"
          style="fill: rgb(38, 50, 56); transform-origin: 163.238px 322.755px;"
          class="animable"
        ></path>
        <g id="eljtzm1s6wo6d">
          <path
            d="M159.93,287.26c9.32,7.49,6.07,43.24,7.82,48.74S177,346,177,346l-10.46,12.25s-8.75-1.52-15.4-15.77S159.93,287.26,159.93,287.26Z"
            style="opacity: 0.2; isolation: isolate; transform-origin: 163.238px 322.755px;"
            class="animable"
          ></path>
        </g>
        <path
          id="elrz5dko3w6cm"
          d="M150,365.75s67-18.69,75.38,4.82S173,412.81,139.37,414,92,391.51,101.92,369.08Z"
          style="fill: rgb(255, 64, 64); transform-origin: 162.465px 386.375px;"
          class="animable"
        ></path>
        <g id="elhw89bv72vah">
          <path
            d="M150,365.75s67-18.69,75.38,4.82S173,412.81,139.37,414,92,391.51,101.92,369.08Z"
            style="opacity: 0.2; isolation: isolate; transform-origin: 162.465px 386.375px;"
            class="animable"
          ></path>
        </g>
        <path
          id="elwsalbyzu6j9"
          d="M215.67,403.8a.23.23,0,0,1-.25,0,.17.17,0,0,1-.06-.21c.11-.37,1.23-3.61,2.35-3.95a.74.74,0,0,1,.68.2,1.15,1.15,0,0,1,.39,1.09,3,3,0,0,1-1.05,1.52A12.94,12.94,0,0,1,215.67,403.8Zm.29-.63a4.76,4.76,0,0,0,2.39-2.31.73.73,0,0,0-.28-.74.22.22,0,0,0-.26-.07.61.61,0,0,0-.26.15A9.17,9.17,0,0,0,216,403.17Z"
          style="fill: rgb(255, 64, 64); transform-origin: 217.072px 401.73px;"
          class="animable"
        ></path>
        <path
          id="eldb3yvw5dsw"
          d="M215.61,403.84a.2.2,0,0,1-.21-.06c-.11-.09-1.66-2.06-1.4-3.38a1.25,1.25,0,0,1,.53-.81.72.72,0,0,1,.9-.08c.81.57.71,3.19.31,4.19l0,.1Zm-.89-3.85a.82.82,0,0,0-.26.47c-.17.84.61,2.1,1,2.7.25-1.12.17-3-.31-3.29-.05,0-.15-.11-.39.06l-.08.06Z"
          style="fill: rgb(255, 64, 64); transform-origin: 215.006px 401.619px;"
          class="animable"
        ></path>
        <path
          id="el0l7gxu9z9y8o"
          d="M147.4,415.69a.24.24,0,0,1,.18-.17.18.18,0,0,1,.19.11c.2.34,1.73,3.4,1.18,4.44a.68.68,0,0,1-.61.34,1.12,1.12,0,0,1-1.05-.47,3,3,0,0,1-.35-1.82A12.7,12.7,0,0,1,147.4,415.69Zm.24.64a4.83,4.83,0,0,0,0,3.33.74.74,0,0,0,.73.32.25.25,0,0,0,.24-.14,1,1,0,0,0,.07-.29A9.47,9.47,0,0,0,147.64,416.33Z"
          style="fill: rgb(255, 64, 64); transform-origin: 147.993px 417.968px;"
          class="animable"
        ></path>
        <polygon
          id="el3qrx9725uuh"
          points="163.63 393.07 151.78 401.76 146.09 405.93 149.8 412.42 155.58 409.26 169.69 401.54 163.63 393.07"
          style="fill: rgb(235, 179, 118); transform-origin: 157.89px 402.745px;"
          class="animable"
        ></polygon>
        <path
          id="elz68g9pww3h"
          d="M151.64,412.22l-3.58-7.89a.85.85,0,0,0-1.15-.41l-3.86,1.89s-5.37,2.17-6,3.91c-2,5.1,5.49,22.75,7.82,22.76.85,0,1.36-1.32,1.48-2.16.37-2.68.44-9.4.78-11.63.4-2.6,3-4.6,4.25-5.4A.86.86,0,0,0,151.64,412.22Z"
          style="fill: rgb(38, 50, 56); transform-origin: 144.221px 418.156px;"
          class="animable"
        ></path>
        <path
          id="elwlm03u263zj"
          d="M147.41,415.62a.21.21,0,0,1,.19-.11c.14,0,2.63.25,3.4,1.36a1.28,1.28,0,0,1,.2.95.72.72,0,0,1-.57.7c-1,.18-2.78-1.72-3.21-2.7l0-.09A.21.21,0,0,1,147.41,415.62Zm3.37,2.06a.8.8,0,0,0-.15-.52c-.48-.7-1.93-1-2.65-1.15.62,1,2,2.2,2.56,2.09.06,0,.18,0,.23-.32v-.1Z"
          style="fill: rgb(255, 64, 64); transform-origin: 149.314px 417.021px;"
          class="animable"
        ></path>
        <g id="ela9kwaj3xdhr">
          <polygon
            points="163.63 393.07 151.78 401.76 155.58 409.26 169.69 401.54 163.63 393.07"
            style="opacity: 0.2; isolation: isolate; transform-origin: 160.735px 401.165px;"
            class="animable"
          ></polygon>
        </g>
        <polygon
          id="el3q7hpstnesl"
          points="158.28 409.75 162.18 408.88 155.71 396.52 152.09 400.21 158.28 409.75"
          style="fill: rgb(255, 64, 64); transform-origin: 157.135px 403.135px;"
          class="animable"
        ></polygon>
        <g id="ely14rjbv7qu">
          <polygon
            points="158.28 409.75 162.18 408.88 155.71 396.52 152.09 400.21 158.28 409.75"
            style="fill: rgb(255, 255, 255); opacity: 0.6; isolation: isolate; transform-origin: 157.135px 403.135px;"
            class="animable"
          ></polygon>
        </g>
        <polygon
          id="el04cgj8hlwbxw"
          points="188.15 408.03 202.65 410.42 209.62 411.57 211.65 404.37 205.35 402.46 189.97 397.78 188.15 408.03"
          style="fill: rgb(235, 179, 118); transform-origin: 199.9px 404.675px;"
          class="animable"
        ></polygon>
        <path
          id="elzx25po9915l"
          d="M210.23,403.2l-3.14,8.07a.87.87,0,0,0,.51,1.12l4.05,1.43s5.31,2.32,7,1.58c5-2.16,12.42-19.84,10.79-21.51-.6-.61-1.89-.05-2.58.46-2.17,1.6-7,6.26-8.85,7.57-2.14,1.54-5.41,1.06-6.83.75A.84.84,0,0,0,210.23,403.2Z"
          style="fill: rgb(38, 50, 56); transform-origin: 218.352px 404.585px;"
          class="animable"
        ></path>
        <g id="el33zlh9s7j9g">
          <polygon
            points="188.15 408.03 202.65 410.42 205.35 402.46 189.97 397.78 188.15 408.03"
            style="opacity: 0.2; isolation: isolate; transform-origin: 196.75px 404.1px;"
            class="animable"
          ></polygon>
        </g>
        <path
          id="el7rh53b1uqap"
          d="M202.85,401s-60.28-35.56-80.68-34.61S100,387.6,108.41,398.85s24,14,24,14-10.58-15-9.05-19.16,7.36,9.94,7.36,9.94,37.35,4.89,69.89,7.65Z"
          style="fill: rgb(255, 64, 64); transform-origin: 153.265px 389.611px;"
          class="animable"
        ></path>
        <polygon
          id="eldlj27koa18m"
          points="203.81 400.04 200.46 397.79 196.16 411 201.32 411 203.81 400.04"
          style="fill: rgb(255, 64, 64); transform-origin: 199.985px 404.395px;"
          class="animable"
        ></polygon>
        <g id="elary33dyzp">
          <polygon
            points="203.81 400.04 200.46 397.79 196.16 411 201.32 411 203.81 400.04"
            style="fill: rgb(255, 255, 255); opacity: 0.6; isolation: isolate; transform-origin: 199.985px 404.395px;"
            class="animable"
          ></polygon>
        </g>
        <path
          id="eld4v435qh4k"
          d="M157.89,372c-10.91-21-7.44-52.95,2-84.74-6.6-5.51-32.93-9.23-53-3.39,0,0-12.74,47.21-11.24,98.78Z"
          style="fill: rgb(38, 50, 56); transform-origin: 127.709px 331.784px;"
          class="animable"
        ></path>
        <path
          id="elclpwvdnrgvv"
          d="M134.73,292.42c-8.77,0-14.48-9.2-14.48-9.2,11.5-4,11.54-16.17,11.54-16.17l4.43,4.33,6.31,6.18c-5.25,3.72-2.77,6.94-1.1,8.37a7.68,7.68,0,0,0,1.1.82S143.5,292.42,134.73,292.42Z"
          style="fill: rgb(235, 179, 118); transform-origin: 131.406px 279.735px;"
          class="animable"
        ></path>
        <g id="elzrc2bvwfdpb">
          <path
            d="M141.43,285.93c-10.76,1.21-10-14.9-10-14.9a27.39,27.39,0,0,1,4.82.35l6.31,6.18C137.28,281.28,139.76,284.5,141.43,285.93Z"
            style="opacity: 0.2; isolation: isolate; transform-origin: 136.989px 278.511px;"
            class="animable"
          ></path>
        </g>
        <path
          id="el52rdg5x2d1m"
          d="M149.83,255c-4.07,1.68-3.29,6.13,3.47,14.24,4.16-3.59,6.42-11.75,4.23-14.37S153.39,253.51,149.83,255Z"
          style="fill: rgb(38, 50, 56); transform-origin: 152.864px 261.296px;"
          class="animable"
        ></path>
        <path
          id="ell4lk8yg4zo"
          d="M155.85,273v0a28.43,28.43,0,0,1-1.3,3.18c-4,8.19-15.58,9.78-20.7,1.68l-.06-.08c-3.38-5.45-2.11-10.54-.7-19.36a12.2,12.2,0,0,1,19-8.18C158.83,254.93,158.43,265.47,155.85,273Z"
          style="fill: rgb(235, 179, 118); transform-origin: 144.61px 265.631px;"
          class="animable"
        ></path>
        <path
          id="elt5v849zty6l"
          d="M145.54,263.75c-.16.71.09,1.37.55,1.47s1-.38,1.12-1.09-.09-1.36-.54-1.47S145.74,263.06,145.54,263.75Z"
          style="fill: rgb(38, 50, 56); transform-origin: 146.367px 263.939px;"
          class="animable"
        ></path>
        <path
          id="el6y4e3aztbor"
          d="M153.58,265.57c-.16.7.09,1.36.56,1.46s1-.37,1.13-1.09-.1-1.37-.56-1.47S153.74,264.86,153.58,265.57Z"
          style="fill: rgb(38, 50, 56); transform-origin: 154.419px 265.75px;"
          class="animable"
        ></path>
        <path
          id="elc8qikhnrts6"
          d="M150.88,265.31a25.88,25.88,0,0,0,2,6.82c-1.54.88-3.53-.13-3.53-.13Z"
          style="fill: rgb(213, 135, 69); transform-origin: 151.115px 268.9px;"
          class="animable"
        ></path>
        <path
          id="elf0fns1pdkt"
          d="M143.91,260.72a.37.37,0,0,1-.25-.12.42.42,0,0,1,0-.6,4.21,4.21,0,0,1,3.77-1,.41.41,0,0,1,.29.5v0a.45.45,0,0,1-.53.29h0a3.35,3.35,0,0,0-3,.79A.44.44,0,0,1,143.91,260.72Z"
          style="fill: rgb(38, 50, 56); transform-origin: 145.634px 259.808px;"
          class="animable"
        ></path>
        <path
          id="elw8d2wlmkhq"
          d="M157.85,263.68a.45.45,0,0,1-.32-.33,3.39,3.39,0,0,0-1.92-2.4.44.44,0,0,1-.26-.55.43.43,0,0,1,.54-.26,4.23,4.23,0,0,1,2.46,3,.42.42,0,0,1-.31.51h-.19Z"
          style="fill: rgb(38, 50, 56); transform-origin: 156.845px 261.899px;"
          class="animable"
        ></path>
        <path
          id="elqqzm9n5i6xa"
          d="M138.28,251.52c3.75,2.31,2.27,6.58-5.69,13.5-3.55-4.2-4.48-12.63-1.89-14.85S135,249.51,138.28,251.52Z"
          style="fill: rgb(38, 50, 56); transform-origin: 134.732px 257.053px;"
          class="animable"
        ></path>
        <path
          id="elojdzv6molbd"
          d="M127.38,264.48a8.46,8.46,0,0,0,2.83,5.84c2.24,2,4.63.26,5.08-2.52.41-2.51-.28-6.55-3-7.43S127.23,261.73,127.38,264.48Z"
          style="fill: rgb(235, 179, 118); transform-origin: 131.386px 265.679px;"
          class="animable"
        ></path>
        <path
          id="elwxkd658i4en"
          d="M142.53,272.09s.92,3.18,5.18,3.92"
          style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.557248px; transform-origin: 145.12px 274.05px;"
          class="animable"
        ></path>
        <path
          id="el72mzi5z1fje"
          d="M150.19,265.37a3,3,0,1,1-3-3A3,3,0,0,1,150.19,265.37Z"
          style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.557248px; transform-origin: 147.19px 265.37px;"
          class="animable"
        ></path>
        <path
          id="el7q0uzceg5ia"
          d="M158.36,267.05a3,3,0,1,1-3-3A3,3,0,0,1,158.36,267.05Z"
          style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.557248px; transform-origin: 155.36px 267.05px;"
          class="animable"
        ></path>
        <path
          id="el1blnzp9e32nh"
          d="M150.19,266.19a1.6,1.6,0,0,1,2.11.56"
          style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.557248px; transform-origin: 151.245px 266.375px;"
          class="animable"
        ></path>
        <path
          id="el7sr64rq7587"
          d="M144.19,264.75s-8.69-3.66-10.31-4"
          style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.557248px; transform-origin: 139.035px 262.75px;"
          class="animable"
        ></path>
        <path
          id="elcszx5dzjj1r"
          d="M130.61,250.29s-.9-7.06,6.31-8.23,16.64.64,19.56-.61a4.59,4.59,0,0,1-2.07,3.06s5.5,2,9.83.28c0,0-1.88,5.21-4.31,7.21H162s-4.1,3.92-9.09,4-9.69-6.79-14.63-4.48S130.61,250.29,130.61,250.29Z"
          style="fill: rgb(38, 50, 56); transform-origin: 147.407px 248.725px;"
          class="animable"
        ></path>
        <g id="elii8unonxse8">
          <path
            d="M155.85,273v0a28.43,28.43,0,0,1-1.3,3.18c-4,8.19-15.58,9.78-20.7,1.68l-.06-.08c-2.64-4.69-.53-9.81-.53-9.81l1.54-5,1.12-1.11s.26,5.07,1.05,6.5,5.29,1.85,6.21,2.1,3.1,2.85,4.18,3.55,5,.12,5.51.7S155.85,273,155.85,273Z"
            style="opacity: 0.4; isolation: isolate; transform-origin: 144.156px 272.525px;"
            class="animable"
          ></path>
        </g>
        <g id="elyugdb4kjgal">
          <path
            d="M110.89,353.5l20.74,23s22.92-.51,50.76,13.1c0,0,29.87-12.34,32.62-22.59s-62.71-3-62.71-3Z"
            style="opacity: 0.2; isolation: isolate; transform-origin: 162.992px 371.55px;"
            class="animable"
          ></path>
        </g>
        <path
          id="elrmlhd8t3nd9"
          d="M116.75,346.75,150,357.63S161.88,355,164,355s10.33,6.67,5.69,9-17.31,2.63-18.69,1.88-38.25-9.38-38.25-9.38Z"
          style="fill: rgb(235, 179, 118); transform-origin: 141.92px 356.498px;"
          class="animable"
        ></path>
        <path
          id="el8lyivxv1kfj"
          d="M106.89,283.87c7.52-1.46-5.53,44.38-2.33,51.13s15.69,11,15.69,11l-5.75,12.69s-17-3.21-26.25-15S94.61,286.25,106.89,283.87Z"
          style="fill: rgb(38, 50, 56); transform-origin: 102.85px 321.263px;"
          class="animable"
        ></path>
        <g id="el45gd1go379g">
          <path
            d="M106.89,283.87c7.52-1.46-5.53,44.38-2.33,51.13s15.69,11,15.69,11l-5.75,12.69s-17-3.21-26.25-15S94.61,286.25,106.89,283.87Z"
            style="opacity: 0.2; isolation: isolate; transform-origin: 102.85px 321.263px;"
            class="animable"
          ></path>
        </g>
        <polygon
          id="elnyyuj73zso7"
          points="120.25 345 113.25 358.71 119.25 360 124.75 347.38 120.25 345"
          style="fill: rgb(38, 50, 56); transform-origin: 119px 352.5px;"
          class="animable"
        ></polygon>
        <g id="el62jx6rxusff">
          <polygon
            points="120.25 345 113.25 358.71 119.25 360 124.75 347.38 120.25 345"
            style="fill: rgb(255, 255, 255); opacity: 0.2; isolation: isolate; transform-origin: 119px 352.5px;"
            class="animable"
          ></polygon>
        </g>
        <path
          id="el0hi9oxycgxst"
          d="M134,364v2.18a2.83,2.83,0,0,0,2.7,2.82h80.39a3,3,0,0,0,2.91-2.82V364Z"
          style="fill: rgb(38, 50, 56); transform-origin: 177px 366.5px;"
          class="animable"
        ></path>
        <g id="elmxvmiomvid">
          <path
            d="M134,364v2.18a2.83,2.83,0,0,0,2.7,2.82h80.39a3,3,0,0,0,2.91-2.82V364Z"
            style="fill: rgb(255, 255, 255); opacity: 0.2; isolation: isolate; transform-origin: 177px 366.5px;"
            class="animable"
          ></path>
        </g>
        <g id="elt2iglqxji5f">
          <path
            d="M164,364v2.18a2.84,2.84,0,0,0,2.71,2.82h50.38a3,3,0,0,0,2.91-2.82V364Z"
            style="fill: rgb(255, 255, 255); opacity: 0.2; isolation: isolate; transform-origin: 192px 366.5px;"
            class="animable"
          ></path>
        </g>
        <path
          id="el418gdrhecor"
          d="M166.71,367h53.11a4.33,4.33,0,0,0,3.86-2.85l12.18-38.21a2.11,2.11,0,0,0-2-2.94H180.71a4.44,4.44,0,0,0-3.86,2.94L164.67,364.1A2.08,2.08,0,0,0,166.71,367Z"
          style="fill: rgb(38, 50, 56); transform-origin: 200.265px 345.002px;"
          class="animable"
        ></path>
        <g id="el4n7bzv4ozci">
          <path
            d="M166.71,367h53.11a4.33,4.33,0,0,0,3.86-2.85l12.18-38.21a2.11,2.11,0,0,0-2-2.94H180.71a4.44,4.44,0,0,0-3.86,2.94L164.67,364.1A2.08,2.08,0,0,0,166.71,367Z"
            style="fill: rgb(255, 255, 255); opacity: 0.2; isolation: isolate; transform-origin: 200.265px 345.002px;"
            class="animable"
          ></path>
        </g>
        <g id="elmfqk0pp4skl">
          <path
            d="M168.71,367h51.11a4.33,4.33,0,0,0,3.86-2.85l12.18-38.21a2.11,2.11,0,0,0-2-2.94H182.71a4.44,4.44,0,0,0-3.86,2.94L166.67,364.1A2.08,2.08,0,0,0,168.71,367Z"
            style="fill: rgb(255, 255, 255); opacity: 0.2; isolation: isolate; transform-origin: 201.265px 345.002px;"
            class="animable"
          ></path>
        </g>
        <g id="elxxlewh85lbj">
          <polygon
            points="205.32 340.81 197.97 346.02 201.15 351.22 208.5 346.02 205.32 340.81"
            style="fill: rgb(255, 255, 255); opacity: 0.2; isolation: isolate; transform-origin: 203.235px 346.015px;"
            class="animable"
          ></polygon>
        </g>
      </g>
    </g>
    <g
      id="freepik--Tabs--inject-2"
      class="animable"
      style="transform-origin: 283.14px 158.2px;"
    >
      <g id="elz8hspkbfpy">
        <rect
          x="166.12"
          y="93.7"
          width="84.73"
          height="84.73"
          rx="8.74"
          style="opacity: 0.2; isolation: isolate; transform-origin: 208.485px 136.065px;"
          class="animable"
        ></rect>
      </g>
      <rect
        id="el5a8zq9o20kp"
        x="189.88"
        y="84.4"
        width="71"
        height="71"
        rx="7.33"
        style="fill: rgb(255, 64, 64); isolation: isolate; transform-origin: 225.38px 119.9px;"
        class="animable"
      ></rect>
      <g id="el3ovbdgerrlc">
        <rect
          x="189.88"
          y="84.4"
          width="71"
          height="71"
          rx="7.33"
          style="fill: rgb(255, 255, 255); opacity: 0.3; isolation: isolate; transform-origin: 225.38px 119.9px;"
          class="animable"
        ></rect>
      </g>
      <path
        id="el1cier3ov648h"
        d="M230.85,111.7v-4.29l-2.21-.21a11.4,11.4,0,0,0-1.65-4l1.42-1.72-3-3-1.71,1.42a11.53,11.53,0,0,0-4-1.65L219.48,96h-4.29L215,98.25a11.53,11.53,0,0,0-4,1.65l-1.71-1.42-3,3,1.42,1.72a11.4,11.4,0,0,0-1.65,4l-2.21.21v4.29l2.21.21a11.53,11.53,0,0,0,1.65,4l-1.42,1.72,3,3,1.71-1.42a11.53,11.53,0,0,0,4,1.65l.21,2.21h4.29l.21-2.21a11.53,11.53,0,0,0,4-1.65l1.71,1.42,3-3L227,115.88a11.53,11.53,0,0,0,1.65-4Zm-13.51,3.67a5.82,5.82,0,1,1,5.81-5.81A5.82,5.82,0,0,1,217.34,115.37Z"
        style="fill: rgb(255, 255, 255); transform-origin: 217.35px 109.535px;"
        class="animable"
      ></path>
      <path
        id="elb9t0138uf3v"
        d="M248.25,123.83v-3.37l-1.73-.16a8.92,8.92,0,0,0-1.29-3.11l1.11-1.34L244,113.47l-1.34,1.11a8.92,8.92,0,0,0-3.11-1.29l-.16-1.74H236l-.16,1.74a8.92,8.92,0,0,0-3.11,1.29l-1.34-1.11L229,115.85l1.11,1.34a8.92,8.92,0,0,0-1.29,3.11l-1.73.16v3.37l1.73.16a8.92,8.92,0,0,0,1.29,3.11L229,128.44l2.38,2.38,1.34-1.11a8.92,8.92,0,0,0,3.11,1.29l.16,1.73h3.37l.16-1.73a8.92,8.92,0,0,0,3.11-1.29l1.34,1.11,2.38-2.38-1.11-1.34a8.92,8.92,0,0,0,1.29-3.11Zm-10.59,2.87a4.56,4.56,0,1,1,4.56-4.56A4.56,4.56,0,0,1,237.66,126.7Z"
        style="fill: rgb(255, 255, 255); transform-origin: 237.67px 122.14px;"
        class="animable"
      ></path>
      <path
        id="elufjah6m8pan"
        d="M230.85,133.38v-2.83l-1.46-.13a7.44,7.44,0,0,0-1.08-2.61l.93-1.13-2-2-1.13.93a7.6,7.6,0,0,0-2.61-1.08l-.14-1.46h-2.83l-.14,1.46a7.6,7.6,0,0,0-2.61,1.08l-1.13-.93-2,2,.93,1.13a7.44,7.44,0,0,0-1.08,2.61l-1.46.13v2.83l1.46.14a7.6,7.6,0,0,0,1.08,2.61l-.93,1.13,2,2,1.13-.93a7.82,7.82,0,0,0,2.61,1.09l.14,1.45h2.83l.14-1.45a7.82,7.82,0,0,0,2.61-1.09l1.13.93,2-2-.93-1.13a7.6,7.6,0,0,0,1.08-2.61ZM222,135.79a3.83,3.83,0,1,1,3.82-3.82A3.82,3.82,0,0,1,222,135.79Z"
        style="fill: rgb(255, 255, 255); transform-origin: 221.945px 131.97px;"
        class="animable"
      ></path>
      <g id="el1vweyezpjtm">
        <rect
          x="192.88"
          y="84.4"
          width="71"
          height="71"
          rx="7.33"
          style="fill: rgb(255, 255, 255); opacity: 0.2; isolation: isolate; transform-origin: 228.38px 119.9px;"
          class="animable"
        ></rect>
      </g>
      <g id="elq2w93fuc0rf">
        <rect
          x="294"
          y="175.94"
          width="78.33"
          height="78.33"
          rx="8.08"
          style="opacity: 0.2; isolation: isolate; transform-origin: 333.165px 215.105px;"
          class="animable"
        ></rect>
      </g>
      <rect
        id="ello8apyfn9am"
        x="313.68"
        y="171.75"
        width="65.63"
        height="65.63"
        rx="6.77"
        style="fill: rgb(255, 64, 64); isolation: isolate; transform-origin: 346.495px 204.565px;"
        class="animable"
      ></rect>
      <g id="elaaf0xkobx7m">
        <rect
          x="313.68"
          y="171.75"
          width="65.63"
          height="65.63"
          rx="6.77"
          style="fill: rgb(255, 255, 255); opacity: 0.2; isolation: isolate; transform-origin: 346.495px 204.565px;"
          class="animable"
        ></rect>
      </g>
      <path
        id="elsweq7wj7two"
        d="M349,215.11a2.37,2.37,0,1,1-2.36-2.37A2.37,2.37,0,0,1,349,215.11Z"
        style="fill: rgb(255, 255, 255); transform-origin: 346.63px 215.11px;"
        class="animable"
      ></path>
      <path
        id="eljyklyqtb18"
        d="M346.43,191.47A23.52,23.52,0,0,0,322.9,215h18.79a4.43,4.43,0,0,1,4.74-4.64,5.11,5.11,0,0,1,1.41.27l8.81-10.23-6.22,12.1a4.46,4.46,0,0,1,.73,2.5H370A23.53,23.53,0,0,0,346.43,191.47Z"
        style="fill: rgb(255, 255, 255); transform-origin: 346.45px 203.235px;"
        class="animable"
      ></path>
      <g id="eldwqdk8n0tf">
        <rect
          x="316.45"
          y="171.75"
          width="65.63"
          height="65.63"
          rx="6.77"
          style="fill: rgb(255, 255, 255); opacity: 0.2; isolation: isolate; transform-origin: 349.265px 204.565px;"
          class="animable"
        ></rect>
      </g>
      <g id="el11ict6vfgrj">
        <rect
          x="321.7"
          y="113.34"
          width="68.73"
          height="68.73"
          rx="7.09"
          style="opacity: 0.2; isolation: isolate; transform-origin: 356.065px 147.705px;"
          class="animable"
        ></rect>
      </g>
      <rect
        id="elv52in6ymhzo"
        x="340.13"
        y="107.28"
        width="57.59"
        height="57.59"
        rx="5.94"
        style="fill: rgb(255, 64, 64); isolation: isolate; transform-origin: 368.925px 136.075px;"
        class="animable"
      ></rect>
      <g id="el3gol7dhozv7">
        <rect
          x="340.13"
          y="107.28"
          width="57.59"
          height="57.59"
          rx="5.94"
          style="fill: rgb(255, 255, 255); opacity: 0.1; isolation: isolate; transform-origin: 368.925px 136.075px;"
          class="animable"
        ></rect>
      </g>
      <path
        id="elxzbq0dzl6s"
        d="M385,121.89a1.89,1.89,0,0,0-1.89-1.89H354.89a1.89,1.89,0,0,0-1.89,1.89v6.22a1.89,1.89,0,0,0,1.89,1.89h28.22a1.89,1.89,0,0,0,1.89-1.89Zm-9.32,3.37h-1.62v-1.62h1.62Zm4.33.81a1.63,1.63,0,1,1,1.62-1.62A1.62,1.62,0,0,1,380,126.07Z"
        style="fill: rgb(255, 255, 255); transform-origin: 369px 125px;"
        class="animable"
      ></path>
      <path
        id="el7pgtr8udymr"
        d="M385,132.89a1.89,1.89,0,0,0-1.89-1.89H354.89a1.89,1.89,0,0,0-1.89,1.89v6.22a1.89,1.89,0,0,0,1.89,1.89h28.22a1.89,1.89,0,0,0,1.89-1.89Zm-9.32,4h-1.62v-1.62h1.62Zm4.33.81a1.62,1.62,0,1,1,1.62-1.62A1.61,1.61,0,0,1,380,137.69Z"
        style="fill: rgb(255, 255, 255); transform-origin: 369px 136px;"
        class="animable"
      ></path>
      <path
        id="el9mwmsasnrkl"
        d="M385,143.89a1.89,1.89,0,0,0-1.89-1.89H354.89a1.89,1.89,0,0,0-1.89,1.89v6.22a1.89,1.89,0,0,0,1.89,1.89h28.22a1.89,1.89,0,0,0,1.89-1.89Zm-9.32,4.62h-1.62v-1.62h1.62Zm4.33.81a1.62,1.62,0,1,1,1.62-1.62A1.62,1.62,0,0,1,380,149.32Z"
        style="fill: rgb(255, 255, 255); transform-origin: 369px 147px;"
        class="animable"
      ></path>
      <g id="eliei76vp8tlg">
        <rect
          x="342.57"
          y="107.28"
          width="57.59"
          height="57.59"
          rx="5.94"
          style="fill: rgb(255, 255, 255); opacity: 0.2; isolation: isolate; transform-origin: 371.365px 136.075px;"
          class="animable"
        ></rect>
      </g>
      <g id="elzhvmr92gufk">
        <rect
          x="209.61"
          y="166.56"
          width="84.73"
          height="84.73"
          rx="8.74"
          style="opacity: 0.2; isolation: isolate; transform-origin: 251.975px 208.925px;"
          class="animable"
        ></rect>
      </g>
      <rect
        id="elkp8xc2zkm9"
        x="229.49"
        y="160.57"
        width="71"
        height="71"
        rx="7.33"
        style="fill: rgb(255, 64, 64); isolation: isolate; transform-origin: 264.99px 196.07px;"
        class="animable"
      ></rect>
      <g id="elb8dnx2lsw3w">
        <rect
          x="229.49"
          y="160.57"
          width="71"
          height="71"
          rx="7.33"
          style="fill: rgb(255, 255, 255); opacity: 0.5; isolation: isolate; transform-origin: 264.99px 196.07px;"
          class="animable"
        ></rect>
      </g>
      <path
        id="elx5jtaf7kdfr"
        d="M245.35,215.56h0a5.55,5.55,0,0,1,0-7.85l13-12.94a3.11,3.11,0,0,1,4.42,0l3.42,3.41a3.12,3.12,0,0,1,0,4.43l-12.95,12.95A5.53,5.53,0,0,1,245.35,215.56Z"
        style="fill: rgb(255, 255, 255); transform-origin: 255.418px 205.531px;"
        class="animable"
      ></path>
      <polygon
        id="elqa4dsozrye"
        points="269.21 194.25 266.66 191.7 281.29 177.07 284.48 176.43 283.84 179.62 269.21 194.25"
        style="fill: rgb(255, 255, 255); transform-origin: 275.57px 185.34px;"
        class="animable"
      ></polygon>
      <path
        id="el430kr923qfq"
        d="M267.77,197.64l-4.5-4.51a1,1,0,0,1,0-1.45h0a1,1,0,0,1,1.46,0l4.5,4.5a1,1,0,0,1,0,1.46h0A1,1,0,0,1,267.77,197.64Z"
        style="fill: rgb(255, 255, 255); transform-origin: 266.253px 194.66px;"
        class="animable"
      ></path>
      <path
        id="el9uh0pwdqb9m"
        d="M261.71,189l-6.17-6.19c1.15-3.09-1.18-6.89-3.71-7.89l-.05,4.76a2,2,0,0,1-2,2,2,2,0,0,1-2-2V175a6.58,6.58,0,0,0-4.1,5.76,6.11,6.11,0,0,0,5.34,6,5.27,5.27,0,0,0,3-.42l6.22,6.24Z"
        style="fill: rgb(255, 255, 255); transform-origin: 252.695px 183.75px;"
        class="animable"
      ></path>
      <path
        id="elz2u1ujhhf3k"
        d="M282.25,212.48v4.73a6.58,6.58,0,0,0,4.1-5.76,6.12,6.12,0,0,0-5.2-6.09,4.81,4.81,0,0,0-2.85.28l-6.46-6.48-3.54,3.54,6.42,6.43c-1.86,3.8,1,7,3.57,8v-4.7a2,2,0,0,1,2-2A2,2,0,0,1,282.25,212.48Z"
        style="fill: rgb(255, 255, 255); transform-origin: 277.325px 208.185px;"
        class="animable"
      ></path>
      <g id="elk5fsfjqivxi">
        <rect
          x="232.49"
          y="160.57"
          width="71"
          height="71"
          rx="7.33"
          style="fill: rgb(255, 255, 255); opacity: 0.2; isolation: isolate; transform-origin: 267.99px 196.07px;"
          class="animable"
        ></rect>
      </g>
      <g id="elxykqzag3prj">
        <rect
          x="269.41"
          y="62.13"
          width="85.58"
          height="85.58"
          rx="8.83"
          style="fill: rgb(255, 64, 64); isolation: isolate; transform-origin: 312.2px 104.92px; transform: rotate(180deg);"
          class="animable"
        ></rect>
      </g>
      <g id="elj7htkjivucq">
        <rect
          x="249.67"
          y="75.73"
          width="102.14"
          height="102.14"
          rx="10.54"
          style="opacity: 0.2; isolation: isolate; transform-origin: 300.74px 126.8px;"
          class="animable"
        ></rect>
      </g>
      <g id="elqmtsar7x57">
        <rect
          x="269.41"
          y="62.13"
          width="85.58"
          height="85.58"
          rx="8.83"
          style="fill: rgb(255, 255, 255); opacity: 0.5; isolation: isolate; transform-origin: 312.2px 104.92px; transform: rotate(180deg);"
          class="animable"
        ></rect>
      </g>
      <path
        id="el2dphidxdjix"
        d="M312.18,78.41,286,88.69s3.31,36,26.21,42.75c22.91-6.75,26.22-42.75,26.22-42.75Zm-.66,34.86a1.85,1.85,0,0,1-1.45.73h0a1.82,1.82,0,0,1-1.45-.76l-9.19-12.67a1.81,1.81,0,1,1,2.93-2.13l7.76,10.68L322.8,92.38a1.81,1.81,0,0,1,2.53-.35,2,2,0,0,1,.35,2.65Z"
        style="fill: rgb(255, 255, 255); transform-origin: 312.215px 104.925px;"
        class="animable"
      ></path>
      <g id="ely7vaxkxb8q">
        <rect
          x="273.03"
          y="62.13"
          width="85.58"
          height="85.58"
          rx="8.83"
          style="fill: rgb(255, 255, 255); opacity: 0.2; isolation: isolate; transform-origin: 315.82px 104.92px; transform: rotate(180deg);"
          class="animable"
        ></rect>
      </g>
    </g>
    <g
      id="freepik--Promt-2--inject-2"
      class="animable"
      style="transform-origin: 390.435px 313.695px;"
    >
      <rect
        id="elzho7mv57qmr"
        x="347.81"
        y="284.93"
        width="85.25"
        height="57.53"
        style="fill: rgb(255, 64, 64); transform-origin: 390.435px 313.695px;"
        class="animable"
      ></rect>
      <g id="elpisikis4xep">
        <rect
          x="347.81"
          y="284.93"
          width="85.25"
          height="57.53"
          style="fill: rgb(255, 255, 255); opacity: 0.7; isolation: isolate; transform-origin: 390.435px 313.695px;"
          class="animable"
        ></rect>
      </g>
      <rect
        id="el8yuu7z6if7o"
        x="349.39"
        y="289.36"
        width="81.87"
        height="51.18"
        style="fill: rgb(38, 50, 56); transform-origin: 390.325px 314.95px;"
        class="animable"
      ></rect>
      <rect
        id="elr2jgyzsh0tk"
        x="353.14"
        y="297.29"
        width="67.31"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 386.795px 298.04px;"
        class="animable"
      ></rect>
      <rect
        id="el6tmhzz5m9cx"
        x="353.14"
        y="301.54"
        width="57.3"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 381.79px 302.29px;"
        class="animable"
      ></rect>
      <rect
        id="el28b46tm7i43"
        x="353.14"
        y="305.79"
        width="67.31"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 386.795px 306.54px;"
        class="animable"
      ></rect>
      <rect
        id="elrb461nwy5zi"
        x="353.14"
        y="310.04"
        width="57.3"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 381.79px 310.79px;"
        class="animable"
      ></rect>
      <rect
        id="el4bufkgtga9a"
        x="353.14"
        y="314.29"
        width="7.27"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 356.775px 315.04px;"
        class="animable"
      ></rect>
      <rect
        id="ely72ciqds97s"
        x="353.14"
        y="318.54"
        width="37.29"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 371.785px 319.29px;"
        class="animable"
      ></rect>
      <rect
        id="el7io3tbsl9p"
        x="353.14"
        y="322.79"
        width="57.3"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 381.79px 323.54px;"
        class="animable"
      ></rect>
      <rect
        id="el1q8zjsqf87j"
        x="353.14"
        y="327.04"
        width="7.27"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 356.775px 327.79px;"
        class="animable"
      ></rect>
      <rect
        id="elveqalmjwhr8"
        x="353.14"
        y="331.29"
        width="7.27"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 356.775px 332.04px;"
        class="animable"
      ></rect>
    </g>
    <g
      id="freepik--Promt-1--inject-2"
      class="animable"
      style="transform-origin: 144.605px 194.005px;"
    >
      <rect
        id="eld47aqrdobo"
        x="101.98"
        y="165.24"
        width="85.25"
        height="57.53"
        style="fill: rgb(255, 64, 64); transform-origin: 144.605px 194.005px;"
        class="animable"
      ></rect>
      <g id="eld0qx5lyups">
        <rect
          x="101.98"
          y="165.24"
          width="85.25"
          height="57.53"
          style="fill: rgb(255, 255, 255); opacity: 0.7; isolation: isolate; transform-origin: 144.605px 194.005px;"
          class="animable"
        ></rect>
      </g>
      <rect
        id="eloe27carim1f"
        x="103.57"
        y="169.67"
        width="81.87"
        height="51.18"
        style="fill: rgb(38, 50, 56); transform-origin: 144.505px 195.26px;"
        class="animable"
      ></rect>
      <rect
        id="elje3tjf6m3r"
        x="107.32"
        y="177.6"
        width="67.31"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 140.975px 178.35px;"
        class="animable"
      ></rect>
      <rect
        id="el5gx8umcivju"
        x="107.32"
        y="181.85"
        width="57.3"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 135.97px 182.6px;"
        class="animable"
      ></rect>
      <rect
        id="eljlk4yq4bp6q"
        x="107.32"
        y="186.1"
        width="67.31"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 140.975px 186.85px;"
        class="animable"
      ></rect>
      <rect
        id="elqv5uccssy2s"
        x="107.32"
        y="190.35"
        width="57.3"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 135.97px 191.1px;"
        class="animable"
      ></rect>
      <rect
        id="el65qoy7u9v7x"
        x="107.32"
        y="194.6"
        width="7.27"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 110.955px 195.35px;"
        class="animable"
      ></rect>
      <rect
        id="elqbubq4e4dp9"
        x="107.32"
        y="198.85"
        width="37.29"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 125.965px 199.6px;"
        class="animable"
      ></rect>
      <rect
        id="eldibvvcjovub"
        x="107.32"
        y="203.1"
        width="57.3"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 135.97px 203.85px;"
        class="animable"
      ></rect>
      <rect
        id="elavkah6hhwfi"
        x="107.32"
        y="207.35"
        width="7.27"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 110.955px 208.1px;"
        class="animable"
      ></rect>
      <rect
        id="elwy87aqtzsf"
        x="107.32"
        y="211.6"
        width="7.27"
        height="1.5"
        style="fill: rgb(224, 224, 224); transform-origin: 110.955px 212.35px;"
        class="animable"
      ></rect>
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius="2"
        ></feMorphology>
        <feFlood
          flood-color="#32DFEC"
          flood-opacity="1"
          result="PINK"
        ></feFlood>
        <feComposite
          in="PINK"
          in2="DILATED"
          operator="in"
          result="OUTLINE"
        ></feComposite>
        <feMerge>
          <feMergeNode in="OUTLINE"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius="2"
        ></feMorphology>
        <feFlood
          flood-color="#ff0000"
          flood-opacity="0.5"
          result="PINK"
        ></feFlood>
        <feComposite
          in="PINK"
          in2="DILATED"
          operator="in"
          result="OUTLINE"
        ></feComposite>
        <feMerge>
          <feMergeNode in="OUTLINE"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        ></feColorMatrix>
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'LoginArt',
}
</script>

<style>
svg#freepik_stories-operating-system:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-operating-system.animated
  #freepik--background-complete--inject-2 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn;
  animation-delay: 0s;
}
svg#freepik_stories-operating-system.animated #freepik--Shadow--inject-2 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) lightSpeedRight;
  animation-delay: 0s;
}
svg#freepik_stories-operating-system.animated #freepik--Window--inject-2 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) lightSpeedRight;
  animation-delay: 0s;
}
svg#freepik_stories-operating-system.animated #freepik--Bar--inject-2 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn;
  animation-delay: 0s;
}
svg#freepik_stories-operating-system.animated #freepik--Character--inject-2 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideDown;
  animation-delay: 0s;
}
svg#freepik_stories-operating-system.animated #freepik--Tabs--inject-2 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) lightSpeedRight;
  animation-delay: 0s;
}
svg#freepik_stories-operating-system.animated #freepik--Promt-2--inject-2 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn;
  animation-delay: 0s;
}
svg#freepik_stories-operating-system.animated #freepik--Promt-1--inject-2 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) zoomIn;
  animation-delay: 0s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes lightSpeedRight {
  from {
    transform: translate3d(50%, 0, 0) skewX(-20deg);
    opacity: 0;
  }
  60% {
    transform: skewX(10deg);
    opacity: 1;
  }
  80% {
    transform: skewX(-2deg);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
